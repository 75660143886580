<template>
  <div class="download">
    <div class="download_tips">
      最好用的交易系统 请选择您的系统点击下载
    </div>
    <div class="download_url">
      <!-- PC下载 -->
      <!-- <a
        href="https://shituopub.oss-cn-hangzhou.aliyuncs.com/v1.1.0.zip"
        target="_blank"
      >
        <img src="../../static/image/pc.png" />
      </a> -->
      <img v-show="!showCode" src="../../static/image/phone.png" @click="showCode = true"/>
      <img v-show="showCode" class="qrcode" src="../../static/image/qrcode.png" @click="showCode = false" width="385px"/>
    </div>
  </div>
</template>
<script>
export default {
  name: "download",
  data() {
    return {
      showCode: false
    };
  },
  computed: {},
  mounted() {
    window.scroll(0,0); 
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.download {
  padding: 100px 0;
  margin: 0 auto;
  text-align: center;
  background-color: #fbfbfb;
  min-width: 1280px;
  min-height: calc(100vh - 534px);
  .download_tips {
    font-size: 24px;
    font-weight: bold;
    color: #1d1b19;
    line-height: 33px;
    letter-spacing: 1px;
  }
  .download_url {
    margin-top: 60px;
    img {
      margin: 0 20px;
      cursor: pointer;
      box-shadow: 0px 2px 40px 0px rgba(0,0,0,0.08);
      border-radius: 24px;
    }
    .qrcode{
      cursor: pointer;
      vertical-align: top;
      margin-top: 21px;
    }
  }
}
</style>
